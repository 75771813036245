













































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { ChannelEntityModel, ChannelQueryModel } from '@common-src/entity-model/channel-entity';
import ChannelService from '@common-src/service/channel';

@Component
export default class ChannelConfigListComponent extends TableDialogFormComponent<ChannelEntityModel, ChannelQueryModel> {
    ChannelEntityModel = ChannelEntityModel;
    created() {
        this.initTable({
            service: new ChannelService(),
            queryModel: new ChannelQueryModel(),
            tableColumns: ChannelEntityModel.getTableColumns()
        });
        this.getList();
    }
}

